import { useLazyQuery } from "@apollo/client";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Image, Tooltip, useDisclosure } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AppRoute } from "@/AppRoute";
import { useGamification } from "@/hooks/useGamification";
import coinIcon from "@/img/coin.svg";
import { useUser } from "@/providers/useUser";
import { Ranges } from "@/schemaTypes";
import { TestIds } from "@/utils/testIds";
import ModalInteractiveContent from "../InteractiveContent/ModalInteractiveContent";
import { GetGamificationByUserIdDocument } from "./graphql/getGamificationByUserId.generated";

interface GamificationStatsProps {
  displayTooltips?: boolean;
  displayLevel?: boolean;
}

const GamificationStats = ({
  displayTooltips = true,
  displayLevel = true,
}: GamificationStatsProps): JSX.Element => {
  const { user, userCoins } = useUser();
  const { colorLevel, imgLevel, loading, newBadges, newCoins } =
    useGamification();
  const { t } = useTranslation();
  const awardIndicatorColor = useColorModeValue("white", "gray.800");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const {
    isOpen: areCoinsOpen,
    onOpen: openCoins,
    onClose: closeCoins,
  } = useDisclosure();
  const {
    isOpen: areBadgesOpen,
    onOpen: openBadges,
    onClose: closeBadges,
  } = useDisclosure();

  const [gamificationData] = useLazyQuery(GetGamificationByUserIdDocument, {
    fetchPolicy: "no-cache",
  });
  const [stars, setStars] = useState(0);
  const [range, setRange] = useState("");

  const navigate = useNavigate();

  // PROBANDO EL ERROR DE PANTALLAZO EN BLANCO

  const loadDataGamification = async () => {
    const { data } = await gamificationData({
      variables: { userId: user?.id || "" },
    });
    const dataStarts = data?.getGamificationByUserId?.stars || 0;
    const dataRange = data?.getGamificationByUserId?.range || Ranges.Initial;
    setStars(dataStarts);
    setRange(dataRange);
  };
  useEffect(() => {
    loadDataGamification();
  }, [user]);

  const handleClick = useCallback(() => {
    navigate(AppRoute.MyProfileGamification);
  }, []);

  return (
    <Flex
      cursor={"pointer"}
      display={"inline-flex"}
      onClick={handleClick}
      overflow={"hidden"}
      rounded={"8px"}
      data-cy={TestIds.Gamification}
      maxH={"32px"}
    >
      <Tooltip
        bgColor={"yellow.400"}
        color={awardIndicatorColor}
        fontWeight={"bold"}
        hasArrow
        isOpen={(displayTooltips && newCoins > 0) || areCoinsOpen}
        label={areCoinsOpen ? t("Coin", { count: userCoins }) : `+${newCoins}`}
        mt={"8px"}
        placement={"bottom"}
      >
        <Flex
          align={"center"}
          bgColor={"neutral.50"}
          gap={"8px"}
          onMouseOut={closeCoins}
          onMouseOver={newCoins === 0 ? openCoins : undefined}
          p={"8px"}
          pr={"16px"}
        >
          <Box bgColor={"white"} p={"3px"} rounded={"50%"}>
            <Image alt={t("Star")} boxSize={"18px"} src={coinIcon} />
          </Box>
          {loading ? (
            <Spinner bgColor={"primary"} size={"xs"} />
          ) : (
            <Text data-cy={TestIds.UserCoins} variant={"caption"}>
              {stars}
            </Text>
          )}
        </Flex>
      </Tooltip>
      <Tooltip
        bgColor={"blue.400"}
        color={awardIndicatorColor}
        fontWeight={"bold"}
        hasArrow
        isOpen={(displayTooltips && newBadges > 0) || areBadgesOpen}
        label={areBadgesOpen ? t("Level") : `+${newBadges}`}
        mt={"8px"}
        placement={"bottom"}
      >
        <Flex
          align={"center"}
          bgColor={colorLevel}
          display={
            displayLevel
              ? range === Ranges.Initial
                ? "none"
                : undefined
              : "none"
          }
          onMouseOut={closeBadges}
          onMouseOver={newBadges === 0 ? openBadges : undefined}
          p={"6px 16px"}
        >
          {loading ? (
            <Spinner color={"primary.400"} size={"xs"} />
          ) : (
            <Text data-cy={TestIds.UserLevel} variant={"caption"}>
              {t(range)}
            </Text>
          )}
        </Flex>
      </Tooltip>
      <ModalInteractiveContent
        badge={imgLevel}
        showShining={true}
        description={`¡${t("WinStatusDecription")} ${t(range)}!`}
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        title={`¡${user?.name} ${t("Win")}!`}
        headingSize="h4"
      />
    </Flex>
  );
};

export { GamificationStats };
